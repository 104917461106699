
const dateTypeArray = ["Registration Date", "Submission Date", "Retail Date"];
const brandArray = [
  { text: "MB", value: "MB" },
  { text: "AMG", value: "AMG" },
  { text: "DENZA", value: "DENZA" },
  { text: "SMART", value: "SMART" },
  { text: "EQ", value: "EQ" },
  { text: "All", value: "全部" },
];
const specialUsageArray = [
  "试乘试驾车",
  "代步车",
  "BMBS开发大客户车",
  "代交车",
  "竞拍车",
  "直销车",
  "全部",
];
const retailTypeArray = ["经销商用车", "最终用户用车"];
const restrictedCityArray = ["限牌", "非限牌"];
const isSampledArray = ["NotSample", "InSample", "Sampled"];
const submitModeArray = ["单车上传", "批量上传"];
const checkingResultArray = [
  { text: "Approved", value: "Approved" },
  { text: "Uncheck", value: "Uncheck" },
  { text: "Rejected", value: "Rejected" },
];
import { defineComponent, reactive, ref, nextTick } from "vue";
import { message } from "ant-design-vue";
import {
  SICCOCheckingQueryListType,
  SICCOCheckingSearchListType,
} from "@/views/VehicleLicense/types";
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement";
import useWait from "@/utils/payment/useWait";
import BrandSelector from "@/views/RetailValidation/component/BrandSelector.vue";
export default defineComponent({
  emits: [
    "get-table-data",
    "refresh-table-height",
    "init-table",
    "reset-status",
  ],
  components: {
    BrandSelector,
  },
  props: {
    activekey: String,
  },
  setup(props, context) {
    const waitBrandDataLoaded = useWait();
    let allBrands: string[] = [];
    const dateType = ref<string>("Registration Date");
    const period = ref([]);
    const searchParams = reactive<SICCOCheckingQueryListType>({
      brand: undefined,
      vinNo: "",
      vinNos: [],
      specialUsage: "全部",
      retailType: null,
      restrictedCity: null,
      isSampled: null,
      submitMode: null,
      checkingResult: null,
      dealerName: "",
      dealerCode: "",
      swtCode: "",
      checkingStatus: [],
      isAppeal: undefined,
    });
    const checkingStatusList = [
      { title: "SICCO Init", value: "SICCOInit" },
      { title: "SICCO Checked", value: "SICCOChecked" },
      { title: "SIPO Rejected", value: "SIPORejected" },
      { title: "Released", value: "Released" },
    ];
    const params = reactive<SICCOCheckingSearchListType>({
      brand: undefined,
      brandList: [],
      vinNo: "",
      vinNos: [],
      specialUsage: "全部",
      retailType: "",
      restrictedCity: "",
      retailStartDate: "",
      retailEndDate: "",
      registrationStartDate: "",
      registrationEndDate: "",
      submissionStartDate: "",
      submissionEndDate: "",
      isSampled: "",
      submitMode: "",
      checkingResult: "",
      dealerName: "",
      dealerCode: "",
      swtCode: "",
      licenceCheckStatus: [],
      isAppeal: undefined,
    });
    const paramsData = ref({});
    const isOpen = ref<boolean>(false);
    // 批量复制
    const batchQuery = reactive({
      batchQueryTitle: "",
      batchQueryModal: false,
      batchQueryListTitle: "",
    });
    const textContentNo = ref<string>("");
    const textNoArray = ref<string[]>([]);
    const input = ref();
    const batchPlaceholder = ref(["", "", "", "", "", ""]);

    const init = () => {
      nextTick(() => {
        waitBrandDataLoaded.then(() => {
          context.emit("init-table", allBrands);
        });
      });
    };
    init();

    const refreshTableHeight = () => {
      nextTick(() => {
        context.emit("refresh-table-height", { isOpen: isOpen.value });
      });
    };

    const close = () => {
      isOpen.value = false;
    };

    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      textNoArray.value =
        cleanEmptyEleArray(searchParams.vinNos)[0] === ""
          ? []
          : cleanEmptyEleArray(searchParams.vinNos);
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ["", "", "", "", "", ""];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = "车架号批量查询";
      batchQuery.batchQueryListTitle = "车架号列表";
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName("list-content")[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      searchParams.vinNo = searchParams.vinNos[0];
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error("请从Excel中粘贴到文本框");
        textContentNo.value = "";
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ",")
          .split(",")
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName("list-content")[0];
      textContentNo.value = "";
      searchParams.vinNos = textNoArray.value;
      // searchParams.vinNos = [];
      // searchParams.vinNos = params.vinNos.concat(textNoArray.value);
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      searchParams.vinNos = [];
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };
    // 重置查询条件
    const resetEvent = () => {
      dateType.value = "Registration Date";
      period.value = [];
      searchParams.brand = undefined;
      searchParams.vinNo = "";
      searchParams.vinNos = [];
      searchParams.specialUsage = "全部";
      searchParams.retailType = "";
      searchParams.restrictedCity = "";
      searchParams.isSampled = "";
      searchParams.submitMode = "";
      searchParams.checkingResult = "";
      searchParams.dealerName = "";
      searchParams.dealerCode = "";
      searchParams.swtCode = "";
      searchParams.checkingStatus = [];
      searchParams.isAppeal = undefined;
      context.emit("reset-status");
    };
    // 获取params
    const getParams = () => {
      params.brand = "";
      params.vinNo = "";
      params.vinNos = [];
      params.specialUsage = "";
      params.retailType = "";
      params.restrictedCity = "";
      params.retailStartDate = "";
      params.retailEndDate = "";
      params.registrationStartDate = "";
      params.registrationEndDate = "";
      params.submissionStartDate = "";
      params.submissionEndDate = "";
      params.isSampled = "";
      params.submitMode = "";
      params.checkingResult = "";
      params.dealerName = "";
      params.dealerCode = "";
      params.swtCode = "";
      params.licenceCheckStatus = [];

      if (searchParams.brand) {
        params.brand = searchParams.brand;
      }

      if (searchParams.vinNo) {
        params.vinNo = searchParams.vinNo.trim();
      }

      if (searchParams.vinNos.length > 0) {
        params.vinNos = cleanEmptyEleArray(searchParams.vinNos);
      }

      if (searchParams.specialUsage) {
        params.specialUsage = searchParams.specialUsage;
      }

      if (searchParams.retailType) {
        params.retailType = searchParams.retailType;
      }

      if (searchParams.restrictedCity) {
        params.restrictedCity = searchParams.restrictedCity;
      }

      if (period.value.length > 0) {
        if (dateType.value == "Registration Date") {
          params.registrationStartDate = period.value[0];
          params.registrationEndDate = period.value[1];
        } else if (dateType.value == "Submission Date") {
          params.submissionStartDate = period.value[0];
          params.submissionEndDate = period.value[1];
        } else {
          params.retailStartDate = period.value[0];
          params.retailEndDate = period.value[1];
        }
      }

      if (searchParams.isSampled) {
        params.isSampled = searchParams.isSampled;
      }

      if (searchParams.submitMode) {
        params.submitMode = searchParams.submitMode;
      }

      if (searchParams.checkingResult) {
        params.checkingResult = searchParams.checkingResult;
      }

      if (searchParams.dealerName) {
        params.dealerName = searchParams.dealerName.trim();
      }

      if (searchParams.dealerCode) {
        params.dealerCode = searchParams.dealerCode.trim();
      }
      if (searchParams.swtCode) {
        params.swtCode = searchParams.swtCode.trim();
      }

      if (searchParams.checkingStatus) {
        params.licenceCheckStatus = searchParams.checkingStatus;
      }
      params.isAppeal = searchParams.isAppeal;
      paramsData.value = params;
      return paramsData;
    };
    // 查询
    const searchEvent = () => {
      getParams();
      context.emit("get-table-data");
    };

    const handleBrandLoaded = (brands: string[]) => {
      allBrands = brands;
      waitBrandDataLoaded.toRun?.();
    };

    return {
      checkingStatusList,
      init,
      dateType,
      period,
      searchParams,
      params,
      isOpen,
      dateTypeArray,
      batchQuery,
      textContentNo,
      textNoArray,
      input,
      batchPlaceholder,
      setBatchQueryTextNoArray,
      setBatchPlaceholder,
      batchQueryEvent,
      closeBatchQueryModal,
      textChangEvent,
      clearBatchQuery,
      brandArray,
      specialUsageArray,
      retailTypeArray,
      restrictedCityArray,
      isSampledArray,
      submitModeArray,
      checkingResultArray,
      resetEvent,
      searchEvent,
      getParams,
      paramsData,
      close,
      refreshTableHeight,
      handleBrandLoaded,
    };
  },
});
