
const dateTypeArray = ["Registration Date", "Submission Date", "Retail Date"];
const brandArray = [
  { text: "MB", value: "MB" },
  { text: "AMG", value: "AMG" },
  { text: "DENZA", value: "DENZA" },
  { text: "SMART", value: "SMART" },
  { text: "EQ", value: "EQ" },
  { text: "All", value: "全部" },
];
const specialUsageArray = [
  "试乘试驾车",
  "代步车",
  "BMBS开发大客户车",
  "代交车",
  "竞拍车",
  "直销车",
  "全部",
];
const retailTypeArray = ["经销商用车", "最终用户用车"];
const restrictedCityArray = ["限牌", "非限牌"];
const isSampledArray = ["NotSample", "InSample", "Sampled"];
const submitModeArray = ["单车上传", "批量上传"];
const checkingResultArray = [
  { text: "核查通过", value: "Approved" },
  { text: "未核查", value: "Uncheck" },
  { text: "核查未通过", value: "Rejected" },
];
import { defineComponent, reactive, ref, nextTick, onMounted } from "vue";
import moment, { Moment } from "moment";
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement";
import useWait from "@/utils/payment/useWait";
import { message } from "ant-design-vue";
import {
  SICCOCheckingQueryListType,
  SICCOCheckingSearchListType,
} from "@/views/VehicleLicense/types";
import BrandSelector from "@/views/RetailValidation/component/BrandSelector.vue";

export default defineComponent({
  emits: [
    "refresh-table-height",
    "reset-status",
    "get-table-data-order",
    "get-over-list-param",
  ],
  components: {
    BrandSelector,
  },
  setup(_, context) {
    const waitBrandDataLoaded = useWait();
    let allBrands: string[] = [];
    const dateType = ref<string>("Registration Date");
    const getDataL = () => {
      const gettoday = new Date();
      const yesterday = new Date(gettoday.getTime() - 24 * 60 * 60 * 1000);
      return yesterday;
    };

    const getDataR = () => {
      const date: any = new Date();
      const givenMonth = date.getMonth();
      const newMonth = givenMonth - 5;
      date.setMonth(newMonth);
      date.setDate(1);
      return date;
    };
    const period = ref<moment.Moment[]>([
      moment(getDataR(), "YYYY/MM/DD"),
      moment(getDataL(), "YYYY/MM/DD"),
    ]);

    const searchParam = reactive<SICCOCheckingQueryListType>({
      brand: undefined,
      vinNo: "",
      vinNos: [],
      specialUsage: "",
      retailType: "",
      restrictedCity: "",
      isSampled: "",
      submitMode: "",
      checkingResult: "",
      dealerName: "",
      dealerCode: "",
      swtCode: "",
      checkingStatus: [],
      isAppeal: undefined
    });
    const checkingStatusList = [
      { title: 'SICCO Init', value: 'SICCOInit' },
      { title: 'SICCO Checked', value: 'SICCOChecked' },
      { title: 'SIPO Rejected', value: 'SIPORejected' },
      { title: 'Released', value: 'Released' },
    ];
    const param = reactive<SICCOCheckingSearchListType>({
      brand: undefined,
      brandList: [],
      vinNo: "",
      vinNos: [],
      specialUsage: "",
      retailType: "",
      restrictedCity: "",
      retailStartDate: "",
      retailEndDate: "",
      registrationStartDate: "",
      registrationEndDate: "",
      submissionStartDate: "",
      submissionEndDate: "",
      isSampled: "",
      submitMode: "",
      checkingResult: "",
      dealerName: "",
      dealerCode: "",
      swtCode: "",
      licenceCheckStatus: [],
      isAppeal: undefined
    });
    const paramData = ref({});
    const isOpen = ref<boolean>(false);
    const registration = ref([]);
    const submission = ref([]);
    // 批量复制
    const batchQuery = reactive({
      batchQueryTitle: "",
      batchQueryModal: false,
      batchQueryListTitle: "",
    });
    const textContentNo = ref<string>("");
    const textNoArray = ref<string[]>([]);
    const input = ref();
    const batchPlaceholder = ref(["", "", "", "", "", ""]);
    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      textNoArray.value =
        cleanEmptyEleArray(searchParam.vinNos)[0] === ""
          ? []
          : cleanEmptyEleArray(searchParam.vinNos);
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ["", "", "", "", "", ""];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = "车架号批量查询";
      batchQuery.batchQueryListTitle = "车架号列表";
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName("list-content")[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      searchParam.vinNo = searchParam.vinNos[0];
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error("请从Excel中粘贴到文本框");
        textContentNo.value = "";
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ",")
          .split(",")
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName("list-content")[0];
      textContentNo.value = "";
      searchParam.vinNos = [];
      searchParam.vinNos = param.vinNos.concat(textNoArray.value);
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      searchParam.vinNos = [];
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };
    const refreshTableHeight = () => {
      nextTick(() => {
        context.emit("refresh-table-height", { isOpen: isOpen.value });
      });
    };
    const close = () => {
      isOpen.value = false;
    };

    // 重置查询条件
    const resetEvent = () => {
      period.value = [
        moment(getDataR(), "YYYY/MM/DD"),
        moment(getDataL(), "YYYY/MM/DD"),
      ];
      searchParam.brand = undefined;
      searchParam.vinNo = "";
      searchParam.vinNos = [];
      searchParam.specialUsage = "";
      searchParam.retailType = "";
      searchParam.restrictedCity = "";
      searchParam.isSampled = "";
      searchParam.submitMode = "";
      searchParam.checkingResult = "";
      searchParam.dealerName = "";
      searchParam.dealerCode = "";
      searchParam.swtCode = "";
      searchParam.checkingStatus = [];
      searchParam.isAppeal = undefined;
      registration.value = [];
      submission.value = [];
      context.emit("reset-status");
    };
    // 获取param
    const getparam = () => {
      param.brand = "";
      param.vinNo = "";
      param.vinNos = [];
      param.specialUsage = "";
      param.retailType = "";
      param.restrictedCity = "";
      param.retailStartDate = "";
      param.retailEndDate = "";
      param.registrationStartDate = "";
      param.registrationEndDate = "";
      param.submissionStartDate = "";
      param.submissionEndDate = "";
      param.isSampled = "";
      param.submitMode = "";
      param.checkingResult = "";
      param.dealerName = "";
      param.dealerCode = "";
      param.swtCode = "";
      param.licenceCheckStatus = [];
      if (searchParam.vinNos.length > 0) {
        param.vinNos = cleanEmptyEleArray(searchParam.vinNos);
      }
      if (searchParam.vinNo) {
        param.vinNo = searchParam.vinNo.trim();
      }
      if (searchParam.dealerName) {
        param.dealerName = searchParam.dealerName.trim();
      }
      if (searchParam.dealerCode) {
        param.dealerCode = searchParam.dealerCode.trim();
      }
      if (searchParam.swtCode) {
        param.swtCode = searchParam.swtCode.trim();
      }
      if (registration.value.length > 0) {
        param.registrationStartDate = registration.value[0];
        param.registrationEndDate = registration.value[1];
      }
      if (submission.value.length > 0) {
        param.submissionStartDate = submission.value[0];
        param.submissionEndDate = submission.value[1];
      }
      if (period.value.length > 0) {
        param.retailStartDate = moment(period.value[0]).format("YYYY/MM/DD");
        param.retailEndDate = moment(period.value[1]).format("YYYY/MM/DD");
      }
      if (searchParam.specialUsage) {
        param.specialUsage = searchParam.specialUsage;
      }
      if (searchParam.brand) {
        param.brand = searchParam.brand;
        param.brandList = [];
      } else {
        param.brandList = allBrands;
      }
      if (searchParam.retailType) {
        param.retailType = searchParam.retailType;
      }
      if (searchParam.restrictedCity) {
        param.restrictedCity = searchParam.restrictedCity;
      }
      if (searchParam.isSampled) {
        param.isSampled = searchParam.isSampled;
      }
      if (searchParam.submitMode) {
        param.submitMode = searchParam.submitMode;
      }
      if (searchParam.checkingResult) {
        param.checkingResult = searchParam.checkingResult;
      }
      if (searchParam.checkingStatus) {
        param.licenceCheckStatus = searchParam.checkingStatus;
      }
      param.isAppeal = searchParam.isAppeal;
      return param;
    };

    // 查询
    const searchEvent = () => {
      waitBrandDataLoaded.then(() => {
        context.emit("get-table-data-order", getparam());
        isOpen.value = false;
      });
    };

    const handleBrandLoaded = (brands: string[]) => {
      allBrands = brands;
      waitBrandDataLoaded.toRun?.();
    };

    onMounted(() => {
      searchEvent();
    });

    return {
      checkingStatusList,
      getparam,
      dateType,
      period,
      searchParam,
      registration,
      submission,
      isOpen,
      dateTypeArray,
      brandArray,
      specialUsageArray,
      retailTypeArray,
      restrictedCityArray,
      isSampledArray,
      submitModeArray,
      checkingResultArray,
      paramData,
      batchQuery,
      textContentNo,
      textNoArray,
      input,
      batchPlaceholder,
      close,
      refreshTableHeight,
      batchQueryEvent,
      closeBatchQueryModal,
      textChangEvent,
      clearBatchQuery,
      searchEvent,
      resetEvent,
      moment,
      getDataL,
      getDataR,
      handleBrandLoaded,
    };
  },
});
