
import { defineComponent, ref, reactive, nextTick } from "vue";
import { FileItem } from "@/API/types";
import { uploadFileData, getUploadFileResult } from "@/API/vl/vlSICCOChecking";
import { guid } from "@/utils/index";
const columns = [
  {
    title: "车架号",
    dataIndex: "vinNo",
    key: "vinNo",
    width: 140,
  },
  {
    title: "识别结果",
    dataIndex: "result",
    key: "result",
    width: 150,
    slots: { customRender: "result" },
  },
];
export default defineComponent({
  components: {},
  emits: ["close-upload-visible", "get-modal-info"],
  props: {
    isUploadVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, context) {
    // 获取相关数据
    const modal = (props as any).isUploadVisible;

    // 文件上传
    const uploadFormData = new FormData();
    const uploadFileName = ref<string>("");
    const uploadFileSize = ref<string>("");
    const uploadComplete = ref<boolean>(true);
    // 上传进度条
    const uploadPercent = ref<number>(0);
    const uploadContent = ref<string>("0% Successfully");
    const uploadProStatus = ref<string>("normal");
    const isUploadSuccess = ref<boolean>(false);
    const isSuccess = ref<boolean>(false);
    const uploadConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          uploadPercent.value = complete;
          uploadContent.value = complete + "% Successfully";
        }
      },
    };
    const isContinue = ref<boolean>(false);
    const tableData = ref([]);
    // 弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    const batchNo = ref<string>(guid());
    const isInit = ref<boolean>(true);
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0,
    });
    const tableHeight = ref();
    const result = ref();

    const close = () => {
      if (isInit.value) {
        isSuccess.value = true;
        context.emit("close-upload-visible", {
          isSubVisible: false,
          isSuccess: isSuccess,
        });
      } else {
        if (!isUploadSuccess.value) {
          modalParams.noticeType = "warning";
          modalParams.content =
            "Do you confirm cancel current uploading?";
          modalParams.btnVisible = true;
          isSuccess.value = false;
          sessionStorage.setItem("stopUploadFilesNo", batchNo.value);
          Object.assign(modalData, modalParams);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
        } else {
          isSuccess.value = true;
          context.emit("close-upload-visible", {
            isSubVisible: false,
            isSuccess: isSuccess,
          });
        }
      }
    };

    // 获取结果
    const getUploadRes = async () => {
      try {
        const params = {
          batchNo: batchNo.value,
          current: pagination.currentPage,
          size: pagination.pageSize
        };
        const res = await getUploadFileResult({ params });
        if (res.code !== "0") {
          uploadProStatus.value = "exception";
          uploadPercent.value = 95;
          uploadContent.value = "Failed";
        } else {
          if (res.data && res.data.content[0]) {
            isContinue.value = true; 
            uploadProStatus.value = "normal";
            uploadPercent.value = 100;
            uploadContent.value = "";
            tableData.value = res.data.content;
            pagination.total = res.data.totalElements;
            nextTick(() => {
              if (result.value.clientHeight) {
                tableHeight.value = result.value.clientHeight - 40;
              }
            });
          } else {
            isContinue.value = false;
          }
        }
      } catch (e) {
        uploadProStatus.value = "exception";
        uploadPercent.value = 95;
        uploadContent.value = "Failed";
      }
    };

    // 校验文件
    const beforeUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        const params = reactive({
          noticeType: "",
          content: "",
          btnVisible: false,
        });
        const isExcel =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isExcel) {
          params.noticeType = "error";
          params.content = "文件格式错误，请按照Excel格式进行文件上传";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          return reject(false);
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = "error";
          params.content = "文件大小必须小于5MB!";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          return reject(false);
        }
        const fileLength = fileList.length;
        if (fileLength > 1) {
          params.noticeType = "error";
          params.content = "每次只能上传一个车辆核查结果文件";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          return reject(false);
        }
        return resolve(true);
      });
    };

    const submitUpload = async () => {
      try {
        const res = await uploadFileData(
          uploadConfig,
          batchNo.value,
          uploadFormData
        );
        if (res.code !== "0") {
          isInit.value = true;
          isUploadSuccess.value = false;
          uploadProStatus.value = "exception";
          uploadPercent.value = 95;
          uploadContent.value = "Failed";
          modalParams.content = res.message;
        } else {
          isInit.value = false;
          modalParams.content = "上传成功！";
          uploadProStatus.value = "normal";
          isUploadSuccess.value = true;
        }
      } catch (e) {
        isInit.value = true;
        isUploadSuccess.value = false;
        uploadProStatus.value = "exception";
        uploadPercent.value = 95;
        uploadContent.value = "Failed";
        modalParams.content = "上传失败";
      }
    };

    // 上传文件
    const uploadFile = async () => {
      uploadProStatus.value = "normal";
      await submitUpload();
      if (isUploadSuccess.value) {
        await getUploadRes();
      } else {
        modalParams.noticeType = "error";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
        context.emit("get-modal-info", {
          modalVisible: true,
          modalData: modalData,
        });
      }
    };

    const uploadCustomRequest = (source: any) => {
      uploadFormData.append("file", source.file);
      uploadFileName.value = source.file.name;
      uploadFileSize.value = (source.file.size / 1024 / 1024).toFixed(2) + "MB";
      uploadComplete.value = false;
      isInit.value = false;
      // 自动上传
      uploadFile();
    };

    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getUploadRes();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getUploadRes();
    };

    return {
      columns,
      modal,
      close,
      beforeUpload,
      uploadComplete,
      uploadFile,
      submitUpload,
      uploadCustomRequest,
      uploadPercent,
      uploadConfig,
      uploadProStatus,
      uploadContent,
      isUploadSuccess,
      isSuccess,
      modalData,
      uploadFileData,
      modalParams,
      uploadFileName,
      uploadFileSize,
      isContinue,
      tableData,
      isInit,
      pagination,
      pageChange,
      sizeChange,
      tableHeight,
      result,
    };
  },
});
