
import { defineComponent, reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import SearchList from "@/views/VehicleLicense/SICCOChecking/components/searchList.vue";
import UploadFiles from "@/views/VehicleLicense/SICCOChecking/components/uploadFiles.vue";
import NoticeModal from "@/components/NoticeModal.vue";
import {
  getOverviewNum,
  getSearchResult,
  markSampling,
  cancelUploadFile,
  getSumbitSearch,
  batchSiccoSumbit,
  batchSumbitToSipo,
  getOverviewNumSumbit,
  getBatchOpertionMsg,
} from "@/API/vl/vlSICCOChecking";
import setColumnWidth from "@/utils/rv/setColumnWidth";
import downloadFile from "@/utils/claim/downloadFile";
import OverList from "@/views/VehicleLicense/SICCOChecking/components/overList.vue";
import { EnumCheckStatus } from "@/views/VehicleLicense/types";
import { s3Download } from "@/utils/s3Download";
import { message } from "ant-design-vue";
const columns = [
  { title: "Is Sampled", dataIndex: "isSampled", width: 100 },
  // { title: 'Region', dataIndex: 'region', width: 80 },
  { title: "Dealer Code", dataIndex: "dealerCode", width: 100 },
  {
    title: "SWT Code",
    dataIndex: "swtCode",
    align: "swtCode",
    width: 100,
  },
  { title: "Dealer Name", dataIndex: "dealerName", width: 250 },
  { title: "VIN", dataIndex: "vinNo", width: 168 },
  { title: "Plate No.", dataIndex: "plateNo", width: 90 },
  { title: "Registration Date", dataIndex: "regDate", width: 130 },
  { title: "Submission Date", dataIndex: "submissionDate", width: 130 },
  { title: "Make", dataIndex: "make", width: 90 },
  { title: "Brand", dataIndex: "brand", width: 90 },
  { title: "Customer", dataIndex: "customer", width: 100 },
  { title: "Retail Type", dataIndex: "retailType", width: 104 },
  { title: "Restricted City", dataIndex: "restrictedCity", width: 110 },
  { title: "Retail Date", dataIndex: "retailDate", width: 130 },
  { title: "Engine No", dataIndex: "engineNo", width: 110 },
  { title: "Model", dataIndex: "model", width: 130 },
  {
    title: "Revised by Dealer",
    dataIndex: "isModified",
    width: 130,
    slots: { customRender: "isModified" },
  },
  {
    title: "Retail Validity",
    dataIndex: "checkStatus",
    width: 130,
    slots: { customRender: "checkStatus" },
  },
  {
    title: "Checking Status",
    dataIndex: "licenseCheckStatus",
    width: 130,
  },
  {
    title: "Submit Mode",
    dataIndex: "submitMode",
    width: 110,
  },
  { title: "Auto Check Result", dataIndex: "autoCheckResult", width: 130 },
  { title: "Check Result", dataIndex: "checkResult", width: 130 },
  { title: "Check Date", dataIndex: "checkDate", width: 110 },
  { title: "Checker", dataIndex: "checker", width: 130 },
  { title: "SICCO Submit Date", dataIndex: "siccoSubmitDate", width: 150 },
  { title: "Appeal Check Date", dataIndex: "appealCheckDate", width: 150 },
  { title: "Appeal Checker", dataIndex: "appealChecker", width: 130 },
  {
    title: "SICCO Resubmit Date",
    dataIndex: "siccoCheckSubmitDate",
    width: 160,
  },
  {
    title: "Operation",
    fixed: "right",
    width: 100,
    slots: { customRender: "operation" },
  },
];
const tableWidth = window.innerWidth;
export default defineComponent({
  components: {
    SearchList,
    UploadFiles,
    NoticeModal,
    OverList,
  },
  setup() {
    const activeKey = ref<string>("1");
    const status = ref<string>("");
    const router = useRouter();
    const params = ref({});
    const searchList = ref();
    const tableDataSumbit = ref([]);
    const overViewNum = reactive({
      appealUnChecked: "",
      totalSubmit: "",
      unChecked: "",
      submitted: "",
      autoChecked: "",
      checked: "",
    });
    // 表格展示
    const tableData = ref([]);
    const tableHeight = ref();
    const tabCon = ref();
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0,
    });
    const currentPage = ref(1);
    const pageSumbitSize = ref(20);
    const isClick = ref<boolean>(true);
    // 文件上传
    const isUploadVisible = ref<boolean>(false);
    // 弹窗初始化
    const modalVisible = ref<boolean>(false);
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      // content2: "",
      btnVisible: false,
      approvedNo: 0,
      rejectedNo: 0,
      totalNo: 0,
    });
    const cancelNum = ref<number>(0);
    const totalSumbit = ref(0);
    const batchClick = ref(false);

    let searchListBrands: string[] = [];

    // 重置tab面板滚动条位置
    const changeTab = (event: string) => {
      const dom =
        document.getElementsByClassName("ant-table-body")[~~event - 1];
      if (dom) {
        dom.scrollTo(0, 0);
      }
    };

    const refreshParams = () => {
      params.value = {};
      const json = searchList.value.getParams();
      for (const key in json) {
        params[key] = json[key];
      }
      params.value["status"] = status.value;
      params.value["current"] = pagination.currentPage;
      params.value["size"] = pagination.pageSize;
    };

    // 获取overview数据数量
    const getOverview = () => {
      getOverviewNum({ brandList: searchListBrands }).then((res: any): void => {
        overViewNum.totalSubmit = res.totalSubmit;
        overViewNum.unChecked = res.unChecked;
        overViewNum.appealUnChecked = res.appealUnChecked;
      });
    };

    const refreshTableHeight = (param: { isOpen: boolean }) => {
      if (param.isOpen) {
        (document.getElementsByClassName("table-box")[0] as any).style.height =
          window.innerHeight - 220 - tabCon.value.scrollHeight + "px";
      } else {
        if (tableData.value && tableData.value.length > 0) {
          (
            document.getElementsByClassName("table-box")[0] as any
          ).style.height =
            window.innerHeight - 270 - tabCon.value.scrollHeight + "px";
        } else {
          (
            document.getElementsByClassName("table-box")[0] as any
          ).style.height =
            window.innerHeight - 230 - tabCon.value.scrollHeight + "px";
        }
      }
      const height = (document.getElementsByClassName("table-box")[0] as any)
        .style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf("px")));
      tableHeight.value = heightNum - 60;
    };

    const getTableData = () => {
      (
        params.value as {
          brandList: string[];
        }
      ).brandList = (params.value as { brand: string }).brand
        ? []
        : searchListBrands;
      getSearchResult(params.value).then((res: any): void => {
        tableData.value = res.content;
        pagination.total = res.totalElements;
        const customerColumn = [
          // 'dealerName',
          "customer",
          "model",
          "checker",
        ];
        tableData.value.length &&
          Object.assign(
            columns,
            setColumnWidth(columns, tableData.value, customerColumn)
          );
        refreshTableHeight({ isOpen: searchList.value.isOpen });
      });
    };

    const initTable = (brands: string[]) => {
      searchListBrands = brands;
      refreshParams();
      getTableData();
      getOverview();
    };

    const resetStatus = () => {
      status.value = "";
    };

    const refreshTableData = () => {
      pagination.currentPage = 1;
      isClick.value = false;
      resetStatus();
      refreshParams();
      params.value["current"] = 1;
      params.value["size"] = 20;
      getTableData();
      getOverview();
      searchList.value.close();
    };
    const param = ref();
    const refreshParam = (getParamData: any) => {
      param.value = {};
      const data = getParamData;
      for (const key in data) {
        param.value[key] = data[key];
      }
      param.value["status"] = status.value;
      param.value["current"] = currentPage.value;
      param.value["size"] = pageSumbitSize.value;
    };

    //点击BatchSumbit按钮
    const batchSumbitEvent = () => {
      getBatchOpertionMsg(param.value).then((res) => {
        if (typeof res != "string") {
          modalParams.approvedNo = res.approvedNo;
          modalParams.rejectedNo = res.rejectedNo;
          modalParams.totalNo = res.totalNo;
          batchClick.value = true;
          modalVisible.value = true;
          modalParams.noticeType = "warning";
          // modalParams.content1 = `Do you confirm sumbit below records to SIPO?`;
          // modalParams.content2 = `Approved No:${approvedNo}    Reject No:${rejectNo} Total No:${totalNo}`;
          modalParams.btnVisible = true;
          Object.assign(modalData, modalParams);
        } else {
          message.error("Please select unsubmitted VIN");
        }
      });
    };

    const getSumbitTable = () => {
      getSumbitSearch(param.value).then((res: any) => {
        tableDataSumbit.value = res.content;
        totalSumbit.value = res.totalElements;
        const customerColumn = ["dealerName", "customer", "model", "checker"];
        tableDataSumbit.value.length &&
          Object.assign(
            columns,
            setColumnWidth(columns, tableDataSumbit.value, customerColumn)
          );
      });
    };

    const getOverviewItem = () => {
      getOverviewNumSumbit(param.value).then((res: any) => {
        overViewNum.submitted = res.submitted;
        overViewNum.autoChecked = res.autoChecked;
        overViewNum.checked = res.checked;
      });
    };

    const refreshSumbitTableData = (paramData: any) => {
      currentPage.value = 1;
      const getParamData = paramData;
      resetStatus();
      refreshParam(getParamData);
      param.value["current"] = 1;
      param.value["size"] = 20;
      getOverviewItem();
      getSumbitTable();
    };

    const gotoCheckingDetailSubmit = async (record: any) => {
      const vinNO = record.vinNo;
      const dealerCode = record.dealerCode;
      await batchSiccoSumbit(vinNO, dealerCode);
      getOverviewItem();
      getOverview();
      getSumbitTable();
    };

    const downloadBatchDoc = () => {
      searchList.value.close();
      resetStatus();
      refreshParams();
      refreshTableHeight({ isOpen: false });
      s3Download({
        url: "./rvapi/vl/handlefile/license/batchDownload",
        method: "POST",
        data: params.value,
        headers: {
          "code-handler": "ignore",
        },
      });
    };

    const exportDoc = () => {
      searchList.value.close();
      refreshParams();
      refreshTableHeight({ isOpen: false });
      const downparams = {
        url: "./rvapi/vl/sicco/criteriaQueryExport",
        method: "post",
        params: params.value,
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(downparams, "application/vnd-excel; char-set=UTF-8");
    };

    const clearParams = () => {
      params.value = {};
      const json = searchList.value.getParams();
      for (const key in json.value) {
        if (key === "vinNos" || key === "licenceCheckStatus") {
          params.value[key] = [];
        } else {
          params.value[key] = "";
        }
      }
      params.value["status"] = status.value;
      params.value["current"] = 1;
      params.value["size"] = 20;
    };

    // 设置checking result下拉框值
    const setCheckingRes = (num: number) => {
      if (num == 1) {
        status.value = "0000"; // Total Submit
      } else if (num == 2) {
        status.value = "1111"; // Unchecked
      } else if (num == 3) {
        status.value = "2222"; // Appeal Unchecked
      } else if (num == 4) {
        status.value = "3333"; // Checked
      } else {
        status.value = "4444"; // Unsubmitted
      }
      isClick.value = false;
      clearParams();
      pagination.currentPage = 1;
      getTableData();
      searchList.value.close();
    };
    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      refreshParams();
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      refreshParams();
      getTableData();
    };
    // 分页逻辑
    const pageSumbitChange = (page: number, pageSize: number) => {
      currentPage.value = page > 0 ? page : 1;
      pageSumbitSize.value = pageSize;
      refreshParam(param.value);
      getSumbitTable();
    };
    const sizeSumbitChange = (page: number, pageSize: number) => {
      currentPage.value = page > 0 ? page : 1;
      pageSumbitSize.value = pageSize;
      refreshParam(param.value);
      getSumbitTable();
    };

    const gotoSampling = async () => {
      searchList.value.close();
      resetStatus();
      refreshParams();
      params.value["isModified"] = "全部";
      params.value["brandList"] = sessionStorage
        .getItem("brandRes")
        ?.split(",");
      await markSampling(params.value).then((res: any) => {
        const paramsRes = {
          total: res.pageData.totalElements,
          size: res.pageData.size,
          data: res.pageData.content,
          allLicenseIdList: res.allLicenseIdList,
          allVehicleIdList: res.allVehicleIdList,
        };
        sessionStorage.setItem(
          "samplingCheckingInitData",
          JSON.stringify(paramsRes)
        );
        router.push({ path: "/vehiclelicense/siccoCheckingSampling" });
      });
      refreshTableHeight({ isOpen: false });
      sessionStorage.setItem(
        "samplingCheckingData",
        JSON.stringify(params.value)
      );
    };

    const gotoCheckingDetail = (record: any, isCheck: any = true) => {
      sessionStorage.setItem("samplingCheckingVinNo", record.vinNo);
      sessionStorage.setItem("samplingCheckingDealerCode", record.dealerCode);
      if (isCheck) {
        router.push({ path: "/vehiclelicense/siccoCheckingDetail" });
      } else {
        router.push({ path: "/vehiclelicense/siccoCheckingDetailView" });
      }
    };

    // 打开文件上传窗口
    const openUploadModal = () => {
      isUploadVisible.value = true;
    };

    // 关闭文件上传窗口时调用
    const closeUpload = (param: {
      isUploadVisible: boolean;
      isSuccess: boolean;
    }) => {
      isUploadVisible.value = param.isUploadVisible;
      if (param.isSuccess) {
        getOverview();
        refreshTableData();
      }
    };

    // 取消上传方法
    const cancelUpload = async (batchNo: string) => {
      try {
        const res = await cancelUploadFile(batchNo);
        cancelNum.value = 1;
        if (res.code !== "0") {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = res.message;
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
        } else {
          isUploadVisible.value = false;
        }
      } catch (e) {
        modalVisible.value = true;
        modalParams.noticeType = "error";
        modalParams.content = "取消上传失败！";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      }
    };

    // 关闭信息弹窗 todo
    const overlist = ref();
    const getdata = ref({});
    const closeModal = async (param: {
      isVisible: boolean;
      continue: boolean;
    }) => {
      modalVisible.value = param.isVisible;
      // 若continue为true 则调用取消上传方法
      if (param.continue) {
        const stopNo = sessionStorage.getItem("stopUploadFilesNo");
        if (stopNo) {
          cancelUpload(stopNo);
        }
        if (batchClick.value) {
          getdata.value = {};
          const getDataItem = overlist.value.getparam();
          for (const key in getDataItem) {
            getdata.value[key] = getDataItem[key];
          }
          getdata.value["current"] = currentPage.value;
          getdata.value["size"] = pageSumbitSize.value;
          getdata.value["status"] = status.value;
          await batchSumbitToSipo(getdata.value);
          getSumbitTable();
          getOverviewItem();
          getOverview();
        }
      }
    };

    // 打开弹窗信息

    const getModalInfo = (param: {
      modalVisible: boolean;
      modalData: object;
    }) => {
      if (cancelNum.value == 1) {
        cancelNum.value = 0;
        return;
      } else {
        modalVisible.value = param.modalVisible;
        Object.assign(modalData, param.modalData);
      }
    };

    const setCheckingSub = (num: number) => {
      if (num === 1) {
        status.value = "submitted";
      } else if (num === 2) {
        status.value = "autoChecked";
      } else {
        status.value = "checked";
      }
      refreshParam(param.value);
      currentPage.value = 1;
      getSumbitTable();
      overlist.value.close();
    };

    const exportSearch = () => {
      overlist.value.close();
      refreshParam(param.value);
      const params = {
        url: "rvapi/vl/sicco/submittedExport",
        method: "post",
        params: param.value,
        fileName: `${+new Date()}.xlsx`,
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };

    nextTick(() => {
      refreshTableHeight({ isOpen: false });
    });

    // UATBUG 17003
    const checkDisabled = (record: any) => {
      const disabledStatus = ["SICCO Checked", "Released"];
      if (disabledStatus.includes(record.licenseCheckStatus)) {
        return true;
      }
      return false;
    };

    return {
      overlist,
      tableWidth,
      columns,
      activeKey,
      status,
      overViewNum,
      params,
      searchList,
      changeTab,
      setCheckingRes,
      tableData,
      pagination,
      refreshTableData,
      clearParams,
      refreshParams,
      getOverview,
      getTableData,
      pageChange,
      sizeChange,
      tableHeight,
      refreshTableHeight,
      tabCon,
      initTable,
      resetStatus,
      gotoSampling,
      downloadBatchDoc,
      exportDoc,
      gotoCheckingDetail,
      isClick,
      openUploadModal,
      isUploadVisible,
      modalVisible,
      modalData,
      modalParams,
      closeUpload,
      cancelUpload,
      closeModal,
      tableDataSumbit,
      getModalInfo,
      cancelNum,
      currentPage,
      pageSumbitSize,
      pageSumbitChange,
      sizeSumbitChange,
      refreshSumbitTableData,
      gotoCheckingDetailSubmit,
      batchSumbitEvent,
      totalSumbit,
      setCheckingSub,
      batchSiccoSumbit,
      exportSearch,
      EnumCheckStatus,
      checkDisabled,
    };
  },
});
